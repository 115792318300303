<template>
  <div class="app-tab pl-3 bg-white shadow-40 text-uppercase">
    <span
      v-for="(option, index) in items"
      :key="`app-tab-option-${option}`"
      class="app-tab-option p-1 mr-3 d-inline-block"
      :class="{
        'text-primary border-1 border-bottom border-primary': forceValue
          ? index === value
          : index === innerVal,
        'text-gray border-0': forceValue ? index !== value : index !== innerVal,
      }"
      @click="forceValue ? $emit('input') : (innerVal = index)"
    >
      {{ option }}
    </span>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';

export default {
  name: 'AppTabs',

  mixins: [_appDefaultInput],

  props: {
    forceValue: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
  },
};
</script>

<style lang="scss" scoped>
.app-tab {
  &-option {
    cursor: pointer;
    transition: color 0.15s ease-in-out;
  }
}
</style>
