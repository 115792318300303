<template>
  <LayoutSuperadmin>
    <template v-slot:title>{{ company ? company.name : 'Company' }}</template>
    <template v-slot:topbar>
      <div class="ml-2">
        <!-- Desktop Button -->
        <router-link
          :to="{
            name: 'MemberAdd',
            query: { company_id: company ? company.id : null },
          }"
        >
          <AppBtn class="text-uppercase mr-3 d-none d-md-inline-block">
            Add Member
          </AppBtn>
        </router-link>
      </div>
    </template>

    <div class="p-3 p-lg-5 h-100 overflow-auto">
      <template v-if="company">
        <GoBackBtn :url="{name: 'VendorList'}" class="mb-2" v-if="company.owner.persona === 'vendor'">Back</GoBackBtn>
        <GoBackBtn :url="{name: 'BuyerList'}" class="mb-2" v-else>Back</GoBackBtn>
        
      </template>
      <div v-if="company">
        <!-- Header -->
        <!-- DISABLED AND MOVED -->
        <div v-if="false" class="w-100 row">
          <div class="col-12 col-lg-8 d-flex align-items-center">
            <div>
              <AppMediaViewer
                width="60"
                :src="company.logo"
                aspectRatio="1/1"
                class="rounded-circle"
              ></AppMediaViewer>
            </div>
            <div class="ml-3 text-uppercase w-100">
              <span class="text-caption text-gray">
                {{ company.owner.persona }}
              </span>
              <div class="d-flex align-items-center w-100">
                <h3 class="text-truncate">{{ company.name }}</h3>
                <AppDropdown>
                  <template v-slot:dropdown-button>
                    <AppIcon hoverable name="ellipsis" class="ml-3"></AppIcon>
                  </template>
                  <template v-slot:dropdown-menu>
                    <div
                      class="text-decoration-none dropdown-item"
                      @click="userAdd.modal = true"
                    >
                      Add new user
                    </div>
                    <div
                      v-if="false"
                      class="text-decoration-none dropdown-item"
                    >
                      Deactivate User
                    </div>
                  </template>
                </AppDropdown>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex justify-content-end">
            <div>
              <span class="text-caption text-gray">TOTAL NUMBER OF SALES</span>
              <h3>$ 31,100</h3>
            </div>
            <div class="ml-3">
              <span class="text-caption text-gray">COMPLETED ORDERS</span>
              <h3>673</h3>
            </div>
          </div>
        </div>
        <!-- Details -->
        <h3 class="mt-5 text-uppercase">Company Details</h3>
        <div class="mt-3 row">
          <div class="col-lg-6 col-xl-4">
            <AppTextLabeled label="COMPANY ADDRESS" class="mt-2 mt-xl-0">
              <span class="font-weight-6">
                {{ company.address }}
              </span>
            </AppTextLabeled>
          </div>
          <div class="col-lg-6 col-xl-4">
            <AppTextLabeled label="EMAIL" class="mt-2 mt-xl-0">
              <span class="font-weight-6">
                {{ company.owner.email }}
              </span>
            </AppTextLabeled>
          </div>
          <div class="col-xl-4">
            <AppTextLabeled label="CONTACT NUMBER" class="mt-2 mt-xl-0">
              <span class="font-weight-6">
                {{ company.owner.mobile_number }}
              </span>
            </AppTextLabeled>
          </div>
        </div>
        <div class="mt-4">
          <h3 class="mt-5 text-uppercase">Members</h3>
          <AppTabs
            v-if="false"
            class="ml-n3 mr-n3"
            :items="['ACTIVE (12)', 'SUSPENDED (1)', 'DELETED (2)']"
          ></AppTabs>

          <router-link
            :to="{
              name: 'MemberAdd',
              query: { company_id: company ? company.id : null },
            }"
          >
            <AppBtn class="text-uppercase d-md-none d-inline-block">
              Add Member
            </AppBtn>
          </router-link>

          <div class="row">
            <div
              v-for="(user, index) in company.users"
              :key="`user-${index}`"
              class="col-12 col-md-6 mb-4"
            >
              <MembersCard
                :user="user"
                :vendor="$route.meta.vendor"
                @update-user="fetchCompanyDetails"
                @dropdown-changed="dropdownChanged"
                :dropdown="dropdownId === user.id"
                @approve-user="fetchCompanyDetails"
                @remove-user="fetchCompanyDetails"
              ></MembersCard>
            </div>
          </div>
        </div>
        <!-- Add Member Modal -->
        <AppModal
          v-model="userAdd.modal"
          skeletal
          width="600px"
          class="overflow-auto"
        >
          <div class="w-100">
            <AppCard>
              <div
                class="p-2 d-flex align-items-center justify-content-between"
              >
                <h4>ADD USER</h4>
              </div>
              <div class="p-2 d-flex align-items-center">
                <div>
                  <AppMediaViewer width="40px"></AppMediaViewer>
                </div>
                <span class="ml-2 font-weight-6">
                  {{ company.name }}
                </span>
              </div>
            </AppCard>

            <AppCard class="mt-2 p-3">
              <h4>USER INFORMATION</h4>
              <AppInputForm
                v-model="userAdd.formValid"
                v-slot="{ validate }"
                @validate="addUser"
              >
                <div class="d-flex">
                  <AppInput
                    v-model="userAdd.form.first_name"
                    label="First Name"
                    class="w-50"
                    :validations="[validation.required()]"
                    :validate="validate"
                  ></AppInput>
                  <AppInput
                    v-model="userAdd.form.last_name"
                    label="Last Name"
                    class="w-50 ml-2"
                    :validations="[validation.required()]"
                    :validate="validate"
                  ></AppInput>
                </div>
                <div>
                  <AppInputSelect
                    v-model="userAdd.form.groups"
                    multiple
                    label="Role"
                    class="w-199"
                    :items="userGroups"
                    :validations="[validation.required()]"
                    :validate="validate"
                  ></AppInputSelect>
                </div>
                <div class="mt-3">
                  <h4>LOG IN CREDENTIALS</h4>
                  <AppInput
                    v-model="userAdd.form.email"
                    label="Email Address"
                    placeholder="Email Address"
                    :validations="[validation.required(), validation.email()]"
                    :validate="validate"
                  ></AppInput>
                  <AppInput
                    v-model="userAdd.form.password"
                    label="Password"
                    type="password"
                    :validations="[validation.required()]"
                    :validate="validate"
                  ></AppInput>
                </div>
                <div
                  class="
                    d-flex
                    flex-column flex-md-row
                    justify-content-center justify-content-md-end
                  "
                >
                  <AppBtn
                    outline
                    :loading="loading"
                    @click="userAdd.modal = false"
                    >Cancel</AppBtn
                  >
                  <AppBtn
                    type="submit"
                    class="mt-2 mt-md-0 ml-md-2"
                    :loading="loading"
                  >
                    Add User
                  </AppBtn>
                </div>
              </AppInputForm>
            </AppCard>
          </div>
        </AppModal>
        <!-- Confirm Add Modal -->
        <ActionModal v-model="userAdd.confirm" @confirm="addUser">
          <template v-slot:title> Add User </template>
          <template v-slot:description> Click confirm to proceed </template>
        </ActionModal>
        <!-- Success Add Modal -->
        <SuccessModal v-model="userAdd.success">
          <template v-slot:title> User Created </template>
          <template v-slot:description>
            New member created on {{ company.name }}
          </template>
          <template v-slot:action-btn-text> Close </template>
        </SuccessModal>
      </div>
      <AppLoading v-else></AppLoading>
    </div>
  </LayoutSuperadmin>
</template>

<script>
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import MembersCard from '@/components/Vendor/Members/MembersCard.vue';
import AppDropdown from '@/shared/elements/AppDropdown.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppTabs from '@/shared/elements/AppTabs.vue';
import AppTextLabeled from '@/shared/elements/AppTextLabeled.vue';
import Company from '@/shared/api/Company';
import User from '@/shared/api/Users';
import AppModal from '@/shared/elements/AppModal.vue';
import AppCard from '@/shared/elements/AppCard.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import AppInputSelect from '@/shared/elements/AppInputSelect.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppLoading from '@/shared/elements/AppLoading.vue';
import LayoutSuperadmin from '../../../components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
import SuccessModal from '../../../components/Partial/Modals/SuccessModal.vue';
import ActionModal from '../../../components/Partial/Modals/ActionModal.vue';
import AppInputForm from '../../../shared/elements/AppInputForm.vue';
import PersonaConstants from '@/shared/constants/PersonaConstants';
import { group_names } from '@/shared/constants/PersonaConstants';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import validation from '@/shared/utils/validation';
import { isEqual } from 'lodash';

const INITIAL_FORM_STATE = {
  first_name: '',
  last_name: '',
  groups: [],
  email: '',
  password: '',
  mobile_number: '',
  get_marketing_update_email: false,
  address: 'N/A',
  city: 'N/A',
  state: 'N/A',
  zip_code: 'N/A',
};
export default {
  name: 'SuperadminCompanyView',

  components: {
    GoBackBtn,
    AppMediaViewer,
    AppIcon,
    AppTextLabeled,
    AppTabs,
    MembersCard,
    AppDropdown,
    AppModal,
    AppCard,
    AppInput,
    AppInputSelect,
    AppBtn,
    AppLoading,
    LayoutSuperadmin,
    SuccessModal,
    ActionModal,
    AppInputForm,
  },

  mixins: [_appApiHelper],

  data() {
    return {
      validation,
      id: this.$route.params.id,
      company: null,
      currentTab: 0,
      tabOptions: ['ACTIVE BUYER (12)', 'SUSPENDED (1)', 'DELETED (2)'],
      userGroups: [],
      userAdd: {
        modal: false,
        success: false,
        confirm: false,
        formValid: false,
        form: { ...INITIAL_FORM_STATE },
      },
      dropdownId: null,
    };
  },

  watch: {
    userAdd: {
      handler(userAdd) {
        const userGroups = this.userGroups;
        if (userGroups.length === 0 || userAdd.form.groups.length === 0) {
          return;
        }

        const adminChosen = userAdd.form.groups.find((group_id) => {
          const groupMatch = userGroups.find(
            (group) => group.text === group_names.admin.name
          );
          return groupMatch.value === group_id;
        });

        if (
          adminChosen &&
          !isEqual(
            userAdd.form.groups,
            userGroups.map((group) => group.value)
          )
        ) {
          this.userAdd.form.groups = userGroups.map((group) => group.value);
        }
      },
      deep: true,
    },
  },

  methods: {
    async fetchCompanyDetails() {
      const response = await Company.getCompany(this.id);
      this.company = response.data;
      const users = this.company.users;
      if (users.length > 0) {
        const ownerConst = group_names.owner;
        const owner = users.find((user) => user.persona === ownerConst.name);
        if (owner) {
          this.company.owner = owner;
        } else {
          this.company.owner = users[0];
        }
      }

      this.$store.dispatch('auth/refreshSelf');
    },
    async fetchUserGroup() {
      const companyType = this.company.owner.persona;
      const persona = PersonaConstants[companyType];

      if (persona && persona.group_names) {
        const owner = persona.group_names.find((group) => group.value === 'owner');
        const response = await User.getUserGroups();
        this.userGroups = response.data
          .filter(
            (group) =>
              group.name !== owner.name &&
              persona.group_names.find((constGroup) => constGroup.name === group.name)
          )
          .map((group) => ({
            text: group.name,
            value: group.id,
          }));
      }
    },
    async addUser() {
      if (!this.userAdd.formValid) {
        return;
      }

      if (!this.userAdd.confirm) {
        return (this.userAdd.confirm = true);
      }

      const form = {
        ...this.userAdd.form,
        company_id: this.company.id,
        company_name: this.company.name,
        company: this.company.name,
        persona: this.company.owner.persona,
      };
      this.$store.dispatch('api/preventSuccessModal');
      const response = await User.postUser(form);
      if (response.status === 200) {
        this.company.users.push(response.data);
        this.resetForm();
        this.$store.dispatch('auth/refreshSelf');
        this.userAdd.success = true;
        this.userAdd.modal = false;
      } else {
        this.userAdd.error = this.error;
      }
      // add user here
    },
    resetForm() {
      this.userAdd.form = { ...INITIAL_FORM_STATE };
    },
    dropdownChanged(payload) {
      if (payload.userId === this.dropdownId) {
        this.dropdownId = null;
        return;
      }

      this.dropdownId = payload.userId;
    },
  },

  async mounted() {
    await this.fetchCompanyDetails();
    await this.fetchUserGroup();
  },
};
</script>

<style lang="scss" scoped></style>
